import api, { ResponseData } from 'api/base';
import type { ApiResponse } from 'apisauce';
import type {
  NotificationData,
  NotificationMeta,
  UserPreferenceData,
  PreferencesBody,
} from './notificationTypes';

const getNotifications = (params: {
  from: string;
  page: number;
  tab?: 'activity' | 'updates';
}): Promise<
ApiResponse<ResponseData<NotificationData[], NotificationMeta>>
> => {
  params.from = 'web';
  return api.get('v4/user/notifications', params);
};

const checkNotification = () => {
  api.patch('v4/user/notifications/check');
};

const confirmNotification = (id = '') => {
  if (!id) return;

  return api.patch(`v4/user/notifications/${id}/confirm`);
};

const getNotificationCount = (): Promise<
ApiResponse<{ data: { new_count: number } }>
> => {
  return api.get('v4/user/notifications/new_count');
};

const getUserPreferences = (): Promise<
ApiResponse<ResponseData<UserPreferenceData>>
> => {
  return api.get('v4/user/preferences');
};

const updateNotificationSetting = (params: {
  preferences: PreferencesBody;
}): Promise<ApiResponse<ResponseData<{}>>> => {
  return api.put('v4/user/preferences/batch', params);
};

const markAllAsRead = () => {
  return api.post('v4/user/notifications/mark_all_as_read');
};

export default {
  getNotifications,
  checkNotification,
  confirmNotification,
  getNotificationCount,
  getUserPreferences,
  updateNotificationSetting,
  markAllAsRead,
};
