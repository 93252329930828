import { navbarBlogImage } from 'theme';

export default [
  {
    link: { as: '/', href: '/blog/' },
    title: 'Most Recent',
    image: navbarBlogImage.mostRecent,
    id: '43841',
    categorySlug: 'blog',
    subcategories: [],
  },
  {
    link: {
      as: '/category/personal-finance-life-stages',
      href: '/blog/category?categorySlug=personal-finance-life-stages',
    },
    title: 'Life Stages',
    image: navbarBlogImage.lifeStages,
    id: '602',
    categorySlug: 'personal-finance-life-stages',
    subcategories: [],
  },
  {
    link: {
      as: '/category/banking',
      href: '/blog/category?categorySlug=banking',
    },
    title: 'Banking',
    image: navbarBlogImage.banking,
    id: '699',
    categorySlug: 'banking',
    subcategories: [
      {
        link: {
          as: '/category/savings-account',
          href: '/blog/category?categorySlug=savings-account',
        },
        title: 'Savings Account',
        id: '536',
      },
      {
        link: {
          as: '/category/fixed-deposits',
          href: '/blog/category?categorySlug=fixed-deposits',
        },
        title: 'Fixed Deposits',
        id: '695',
      },
    ],
  },
  {
    link: {
      as: '/category/loans',
      href: '/blog/category?categorySlug=loans',
    },
    title: 'Loans',
    image: navbarBlogImage.loans,
    id: '550',
    categorySlug: 'loans',
    subcategories: [
      {
        link: {
          as: '/category/education-loans',
          href: '/blog/category?categorySlug=education-loans',
        },
        title: 'Education Loans',
        id: '704',
      },
      {
        link: {
          as: '/category/car-loans',
          href: '/blog/category?categorySlug=car-loans',
        },
        title: 'Car Loans',
        id: '703',
      },
      {
        link: {
          as: '/category/home-loans',
          href: '/blog/category?categorySlug=home-loans',
        },
        title: 'Home Loans',
        id: '705',
      },
      {
        link: {
          as: '/category/renovation-loans',
          href: '/blog/category?categorySlug=renovation-loans',
        },
        title: 'Renovation Loans',
        id: '706',
      },
      {
        link: {
          as: '/category/personal-loans',
          href: '/blog/category?categorySlug=personal-loans',
        },
        title: 'Personal Loans',
        id: '702',
      },
    ],
  },
  {
    link: {
      as: '/category/cards',
      href: '/blog/category?categorySlug=cards',
    },
    title: 'Cards',
    image: navbarBlogImage.cards,
    id: '700',
    categorySlug: 'cards',
    subcategories: [
      {
        link: {
          as: '/category/credit-cards',
          href: '/blog/category?categorySlug=credit-cards',
        },
        title: 'Credit Cards',
        id: '553',
      },
      {
        link: {
          as: '/category/debit-cards',
          href: '/blog/category?categorySlug=debit-cards',
        },
        title: 'Debit Cards',
        id: '707',
      },
      {
        link: {
          as: '/category/multi-currency-cards',
          href: '/blog/category?categorySlug=multi-currency-cards',
        },
        title: 'Multi-Currency Cards',
        id: '708',
      },
    ],
  },
  {
    link: {
      as: '/category/investments',
      href: '/blog/category?categorySlug=investments',
    },
    title: 'Investments',
    image: navbarBlogImage.investments,
    id: '698',
    categorySlug: 'investments',
    subcategories: [
      {
        link: {
          as: '/category/basics-of-investing',
          href: '/blog/category?categorySlug=basics-of-investing',
        },
        title: 'Beginner Investing',
        id: '541',
      },
      {
        link: {
          as: '/category/what-is',
          href: '/blog/category?categorySlug=what-is',
        },
        title: 'What Is...?',
        id: '681',
      },
      {
        link: {
          as: '/category/60-seconds-guide',
          href: '/blog/category?categorySlug=60-seconds-guide',
        },
        title: '60-Second Guide',
        id: '682',
      },
      {
        link: {
          as: '/category/online-brokerages',
          href: '/blog/category?categorySlug=online-brokerages',
        },
        title: 'Online Brokerages',
        id: '712',
      },
      {
        link: {
          as: '/category/reits',
          href: '/blog/category?categorySlug=reits',
        },
        title: 'REITs',
        id: '545',
      },
      {
        link: {
          as: '/category/robo-advisors',
          href: '/blog/category?categorySlug=robo-advisors',
        },
        title: 'Robo Advisors',
        id: '713',
      },
      {
        link: {
          as: '/category/stocks-analysis',
          href: '/blog/category?categorySlug=stocks-analysis',
        },
        title: 'Stocks Analysis',
        id: '599',
      },
      {
        link: {
          as: '/category/stock-investing',
          href: '/blog/category?categorySlug=stock-investing',
        },
        title: 'Stocks Investing',
        id: '552',
      },
      {
        link: {
          as: '/category/stocks-earnings',
          href: '/blog/category?categorySlug=stocks-earnings',
        },
        title: 'Stocks Earnings',
        id: '683',
      },
      {
        link: {
          as: '/category/bonds',
          href: '/blog/category?categorySlug=bonds',
        },
        title: 'Bonds',
        id: '577',
      },
      {
        link: {
          as: '/category/mutual-funds-etfs',
          href: '/blog/category?categorySlug=mutual-funds-etfs',
        },
        title: 'Mutual Funds & ETFs',
        id: '546',
      },
      {
        link: {
          as: '/category/alternative-investments',
          href: '/blog/category?categorySlug=alternative-investments',
        },
        title: 'Alternative Investments',
        id: '547',
      },
    ],
  },
  {
    link: {
      as: '/category/utilities-bills',
      href: '/blog/category?categorySlug=utilities-bills',
    },
    title: 'Utilities & Bills',
    image: navbarBlogImage.utilitiesBills,
    id: '701',
    categorySlug: 'utilities-bills',
    subcategories: [
      {
        link: {
          as: '/category/open-electricity-market',
          href: '/blog/category?categorySlug=open-electricity-market',
        },
        title: 'Electricity Retailers',
        id: '709',
      },
      {
        link: {
          as: '/category/mobile-plans',
          href: '/blog/category?categorySlug=mobile-plans',
        },
        title: 'Mobile Plans',
        id: '710',
      },
      {
        link: {
          as: '/category/broadband',
          href: '/blog/category?categorySlug=broadband',
        },
        title: 'Broadband',
        id: '711',
      },
    ],
  },
  {
    link: {
      as: '/category/insurance',
      href: '/blog/category?categorySlug=insurance',
    },
    title: 'Insurance',
    image: navbarBlogImage.insurance,
    id: '171',
    categorySlug: 'insurance',
    subcategories: [
      {
        link: {
          as: '/category/basics-of-insurance',
          href: '/blog/category?categorySlug=basics-of-insurance',
        },
        title: 'Beginner Insurance',
        id: '554',
      },
      {
        link: {
          as: '/category/insurance-savings-plans',
          href: '/blog/category?categorySlug=insurance-savings-plans',
        },
        title: 'Insurance Savings Plans',
        id: '716',
      },
      {
        link: {
          as: '/category/travel-insurance',
          href: '/blog/category?categorySlug=travel-insurance',
        },
        title: 'Travel Insurance',
        id: '684',
      },
      {
        link: {
          as: '/category/health-insurance',
          href: '/blog/category?categorySlug=health-insurance',
        },
        title: 'Health Insurance',
        id: '556',
      },
      {
        link: {
          as: '/category/life-insurance',
          href: '/blog/category?categorySlug=life-insurance',
        },
        title: 'Life Insurance',
        id: '555',
      },
      {
        link: {
          as: '/category/endowment',
          href: '/blog/category?categorySlug=endowment',
        },
        title: 'Endowment Plan',
        id: '598',
      },
      {
        link: {
          as: '/category/car-insurance',
          href: '/blog/category?categorySlug=car-insurance',
        },
        title: 'Car Insurance',
        id: '604',
      },
      {
        link: {
          as: '/category/investment-linked-policies-ilps',
          href: '/blog/category?categorySlug=investment-linked-policies-ilps',
        },
        title: 'Investment Linked Policies (ILPs)',
        id: '557',
      },
      {
        link: {
          as: '/category/home-insurance',
          href: '/blog/category?categorySlug=home-insurance',
        },
        title: 'Home Insurance',
        id: '605',
      },
      {
        link: {
          as: '/category/motorcycle-insurance',
          href: '/blog/category?categorySlug=motorcycle-insurance',
        },
        title: 'Motorcycle Insurance',
        id: '610',
      },
    ],
  },
  {
    link: {
      as: '/category/property',
      href: '/blog/category?categorySlug=property',
    },
    title: 'Property',
    image: navbarBlogImage.property,
    id: '686',
    categorySlug: 'property',
    subcategories: [
      {
        link: {
          as: '/category/bto-housing',
          href: '/blog/category?categorySlug=bto-housing',
        },
        title: 'HDB BTO',
        id: '173',
      },
      {
        link: {
          as: '/category/hdb-resale',
          href: '/blog/category?categorySlug=hdb-resale',
        },
        title: 'HDB Resale',
        id: '690',
      },
      {
        link: {
          as: '/category/home-renovation',
          href: '/blog/category?categorySlug=home-renovation',
        },
        title: 'Renovation',
        id: '692',
      },
      {
        link: {
          as: '/category/condo',
          href: '/blog/category?categorySlug=condo',
        },
        title: 'Condo',
        id: '689',
      },
      {
        link: {
          as: '/category/property-investing',
          href: '/blog/category?categorySlug=property-investing',
        },
        title: 'Property Investing',
        id: '562',
      },
    ],
  },
  {
    link: {
      as: '/category/financial-lifestyle',
      href: '/blog/category?categorySlug=financial-lifestyle',
    },
    title: 'Lifestyle',
    image: navbarBlogImage.lifestyle,
    id: '53',
    categorySlug: 'financial-lifestyle',
    subcategories: [
      {
        link: {
          as: '/category/basics-of-budgeting',
          href: '/blog/category?categorySlug=basics-of-budgeting',
        },
        title: 'Budgeting',
        id: '544',
      },
      {
        link: {
          as: '/category/retirement-planning',
          href: '/blog/category?categorySlug=retirement-planning',
        },
        title: 'Retirement',
        id: '575',
      },
      {
        link: {
          as: '/category/life-hacks',
          href: '/blog/category?categorySlug=life-hacks',
        },
        title: 'Life Hacks',
        id: '559',
      },
      {
        link: {
          as: '/category/career',
          href: '/blog/category?categorySlug=career',
        },
        title: 'Career',
        id: '548',
      },
      {
        link: {
          as: '/category/dining',
          href: '/blog/category?categorySlug=dining',
        },
        title: 'Dining',
        id: '563',
      },
      {
        link: {
          as: '/category/education',
          href: '/blog/category?categorySlug=education',
        },
        title: 'Education',
        id: '540',
      },
      {
        link: {
          as: '/category/entertainment',
          href: '/blog/category?categorySlug=entertainment',
        },
        title: 'Entertainment',
        id: '560',
      },
      {
        link: {
          as: '/category/expats',
          href: '/blog/category?categorySlug=expats',
        },
        title: 'Expats',
        id: '694',
      },
      {
        link: {
          as: '/category/fitness-beauty',
          href: '/blog/category?categorySlug=fitness-beauty',
        },
        title: 'Fitness & Beauty',
        id: '539',
      },
      {
        link: {
          as: '/category/women-finance',
          href: '/blog/category?categorySlug=women-finance',
        },
        title: 'For Her',
        id: '603',
      },
      {
        link: {
          as: '/category/healthcare',
          href: '/blog/category?categorySlug=healthcare',
        },
        title: 'Healthcare',
        id: '537',
      },
      {
        link: {
          as: '/category/parenting',
          href: '/blog/category?categorySlug=parenting',
        },
        title: 'Parenting',
        id: '571',
      },
      {
        link: {
          as: '/category/shopping',
          href: '/blog/category?categorySlug=shopping',
        },
        title: 'Shopping',
        id: '597',
      },
      {
        link: {
          as: '/category/social-causes',
          href: '/blog/category?categorySlug=social-causes',
        },
        title: 'Social Causes',
        id: '612',
      },
      {
        link: {
          as: '/category/transportation',
          href: '/blog/category?categorySlug=transportation',
        },
        title: 'Transportation',
        id: '572',
      },
      {
        link: {
          as: '/category/travel',
          href: '/blog/category?categorySlug=travel',
        },
        title: 'Travel',
        id: '551',
      },
      {
        link: {
          as: '/category/wedding',
          href: '/blog/category?categorySlug=wedding',
        },
        title: 'Wedding',
        id: '538',
      },
    ],
  },
  {
    link: {
      as: '/category/more',
      href: '/blog/category?categorySlug=more',
    },
    title: 'More',
    image: navbarBlogImage.more,
    id: '568',
    categorySlug: 'more',
    subcategories: [
      {
        link: {
          as: '/category/comics',
          href: '/blog/category?categorySlug=comics',
        },
        title: 'Comics',
        id: '609',
      },
      {
        link: {
          as: '/category/seedly-explains',
          href: '/blog/category?categorySlug=seedly-explains',
        },
        title: 'SeedlyExplains',
        id: '714',
      },
      {
        link: {
          as: '/category/cpf',
          href: '/blog/category?categorySlug=cpf',
        },
        title: 'CPF',
        id: '564',
      },
      {
        link: {
          as: '/category/on-the-economy',
          href: '/blog/category?categorySlug=on-the-economy',
        },
        title: 'On The Economy',
        id: '574',
      },
      {
        link: {
          as: '/category/in-the-news',
          href: '/blog/category?categorySlug=in-the-news',
        },
        title: 'In the News',
        id: '717',
      },
      {
        link: {
          as: '/category/opinion',
          href: '/blog/category?categorySlug=opinion',
        },
        title: 'Opinion',
        id: '566',
      },
      {
        link: {
          as: '/category/scams',
          href: '/blog/category?categorySlug=scams',
        },
        title: 'Scams',
        id: '570',
      },
      {
        link: {
          as: '/category/seedly-community',
          href: '/blog/category?categorySlug=seedly-community',
        },
        title: 'Seedly',
        id: '576',
      },
      {
        link: {
          as: '/category/cryptocurrency',
          href: '/blog/category?categorySlug=cryptocurrency',
        },
        title: 'Cryptocurrency',
        id: '590',
      },
    ],
  },
];
